@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://db.onlinewebfonts.com/c/d65e9c42e68e41f352814a95971b9ee2?family=Donau+Neue);
@import url(https://db.onlinewebfonts.com/c/3770521e8aa0eea189fa45b737561720?family=Abys);
@import url(https://fonts.cdnfonts.com/css/glacial-indifference-2);
@import url(https://fonts.cdnfonts.com/css/black-bones-personal-use);

@font-face {
  font-family: 'Black Bones Personal Use', sans-serif;
  font-weight: normal;
}
 {
  font-family: "Donau Neue";
  src: url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.eot");
  src: url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/d65e9c42e68e41f352814a95971b9ee2.svg#Donau Neue")
      format("svg");
}
 {
  font-family: 'Glacial Indifference', sans-serif;
}

,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
